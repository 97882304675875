import Banner from "../containers/WebApp/Contact/Banner";
import Section from "../containers/WebApp/Contact/Section";
import SEO from "../components/SEO";
import React from "react";

const Contact = () => {
  return (
    <>
      <SEO title="Contact" />
      <Banner />
      <Section />
    </>
  );
};

export default Contact;
